import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom'
import UserDashboard from "../components/user/userPages/userDashboard/UserDashboard";
import PaymentHistory from "../components/user/userPages/paymentHistory/PaymentHistory";
import AllCourses from "../components/user/userPages/allCourses/AllCourses";
import Links from "../components/user/userPages/links/Links";
import Files from "../components/user/userPages/files/Files";
import Library from "../components/user/userPages/library/Library";
import Certificate from "../components/user/userPages/certificate/Certificate";
import Cooperation from "../components/user/userPages/cooperation/Cooperation";
import {useLocation} from 'react-router-dom'
import CertificateTest from "../components/user/userPages/certificate/CertificateTest";
import NotFound from "../components/notFound/NotFound";
import Settings from "../components/user/userPages/settings/Settings";
import CertificatePDF from "../components/user/userPages/certificate/certificatePDF/CertificatePDF";

const RootPage = (props) => {
    const {setPathName} = props;
    const location = useLocation();
    setPathName(location?.pathname);

    return (
        <Routes>
            <Route path='/user/dashboard' element={<UserDashboard/>}/>
            <Route path='/user/all-courses' element={<AllCourses/>}/>
            <Route path='/user/payment-history' element={<PaymentHistory/>}/>
            <Route path='/user/links' element={<Links/>}/>
            <Route path='/user/files' element={<Files/>}/>
            <Route path='/user/library' element={<Library/>}/>
            <Route path='/user/certificate' element={<Certificate/>}/>
            <Route path='/user/test' element={<CertificateTest/>}/>
            {/*<Route path='/user/cooperation' element={<NotFound/>}/>*/}
            <Route path='/user/cooperation' element={<Cooperation/>}/>
            <Route path='/user/settings' element={<Settings/>}/>
            <Route path='/user/questions' element={<NotFound/>}/>
            <Route path='*' element={<Navigate to="/user/dashboard"/>}/>
            <Route path='/not-found' element={<NotFound/>}/>
        </Routes>
    );
};

export default RootPage;