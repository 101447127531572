import React, {useEffect, useState} from 'react';

import './certificate.css';
import './certificatePDF/certificatePDF.css';
import {Collapse} from "antd";
import {Link} from "react-router-dom";
import axios from "axios";
import {ip} from "../../../../ip";
import {storage} from "../../../../services";
import CertificateTest from "./CertificateTest";

import {QRCodeSVG} from 'qrcode.react';
import QRCode from 'qrcode.react';
import Loader from "../../../loading/Loader";

import {jsPDF} from "jspdf";
import html2canvas from 'html2canvas';

import CertificatePDF from "./certificatePDF/CertificatePDF";
import Modal from "react-modal";


const Certificate = () => {
    const token = storage.local.get("token");
    const user = storage.local.get("user");
    const [openTestPage, setOpenTestPage] = useState(false);
    const [testData, setTestData] = useState(null);
    const [certificate, setCertificate] = useState([]);

    const getData = () => {
        axios.get(`${ip}/api/v1/certification/getAllCertificate`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setCertificate(res?.data?.item);
            }).catch((err) => {})
    }
    useEffect(() => {
        getData();
    }, []);


    const [cerDataId, setCerDataId] = useState(null);
    const getTestID = (item) => {
        setCerDataId(item?.id);
        axios.get(`${ip}/api/v1/certification/getAllQuestion?certificateId=${item?.id}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res);
                setTestData(res?.data?.item);
                setOpenTestPage(true);
            }).catch((err) => {
        })

    };
    const [qrCodeLink, setQrCodeLink] = useState('');
    const [certificateInfo, setCertificateInfo] = useState(null);

    const [loading, setLoading] = useState(false);
    const openCer = (id) => {
        setLoading(true);
        // certificate
        axios.get(`${ip}/api/v1/certification/getCertificateInfo?certificateId=${id}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setCertificateInfo(res?.data?.item);
            }).catch((err) => {
        });
    }


    useEffect(() => {
        if (certificateInfo) {
            handleDownloadPDF();
        }
    }, [certificateInfo]);

    const handleDownloadPDF = () => {
        const input = document.getElementById('pdf-content');
        html2canvas(input, {scale: 2}).then((canvas) => {
            const imgData = canvas.toDataURL('image/png', 1.0); // Ensure highest quality
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined, 'SLOW'); // Use 'FAST' , 'SLOW' , 'NONE' compression for better quality
            pdf.save('certificate.pdf');
            setLoading(false);
            setCertificateInfo('');
            setQrCodeLink('');
            setCerDataId(null);
        });
    };

    return (
        <div className="certificate">
            {
                loading && <Loader/>
            }
            {!openTestPage ?
                <div>
                    <div className="certificate_header">
                        <h2>SERTIFIKAT</h2>
                    </div>
                    <div className="certificate_body">
                        {certificate?.map((item, index) => {
                            return (
                                <div className="certificate_body_card" key={index}>
                                    <div className="certificate_body_card_header">
                                        {/*<img loading="lazy" src={caruselImg}/>*/}
                                        <img loading="lazy" src={`${ip}/api/photo/show/${item?.coverPhotoId}`}/>
                                    </div>
                                    <div className="certificate_body_card_body">
                                        {
                                            item?.isAlreadyTaken ?
                                                <div className="certificate_body_card_body_button"
                                                     onClick={() => openCer(item?.id)}>SERTIFIKAT OLINGAN</div>
                                                :
                                                (
                                                    item?.isDone ?
                                                        <div className="certificate_body_card_body_button"
                                                             onClick={() => openCer(item?.id)}>SERTIFIKATNI
                                                            YUKLASH</div>
                                                        :
                                                        <div className="certificate_body_card_body_button"
                                                             onClick={() => getTestID(item)}>TESTNI BOSHLASH</div>
                                                )

                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                :
                <CertificateTest
                    testData={testData}
                    setTestData={setTestData}
                    setOpenTestPage={setOpenTestPage}
                    openCer={openCer}
                    setCerDataId={setCerDataId}
                    cerDataId={cerDataId}
                    getData={getData}
                />
            }

            <CertificatePDF
                certificateInfo={certificateInfo}
                qrCodeLink={qrCodeLink}
            />
        </div>
    );
};

export default Certificate;